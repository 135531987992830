var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"editSourceDocumentDetailsModal",attrs:{"id":"goods-received-note-edit-source-document-details-modal","centered":"","size":"xl","dialog-class":"goods-received-note-edit-source-document-details-modal-dialog","scrollable":"","title":"Editare detalii document sursă","cancel-title":"Renunță","no-close-on-backdrop":""},on:{"cancel":_vm.onModalCancel,"ok":_vm.onModalOk}},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('div',{staticClass:"col-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var invalid = ref.invalid;
var validated = ref.validated;
var validate = ref.validate;
return [_c('b-form-group',{attrs:{"label-size":"sm","label":_vm.$t('nir.create.form.lblTipDocument')}},[_c('multiselect',{class:{ 'is-invalid': validated && invalid, 'is-valid': validated && valid },attrs:{"label":"label","track-by":"key","select-label":"👈","deselect-label":"","selected-label":"✔","disabled":!_vm.isDraft,"allow-empty":false,"options":_vm.sourceDocumentTypeOptions,"searchable":true,"close-on-select":true,"show-labels":true,"placeholder":_vm.$t('nir.create.form.plcTipDocument')},on:{"input":validate,"close":validate},model:{value:(_vm.sourceDocumentType),callback:function ($$v) {_vm.sourceDocumentType=$$v},expression:"sourceDocumentType"}}),_c('transition',{attrs:{"name":"slide-fade-down"}},[(errors[0])?_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)],1)]}}])})],1),_c('div',{staticClass:"col-2"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-size":"sm","label":_vm.$t('nir.create.form.lblNumarDocument')}},[_c('b-form-input',{attrs:{"trim":"","readonly":!_vm.isDraft,"state":!_vm.isDraft ? null : errors[0] ? false : (valid ? true : null),"placeholder":_vm.$t('nir.create.form.plcNumarDocument')},model:{value:(_vm.sourceDocumentNumber),callback:function ($$v) {_vm.sourceDocumentNumber=$$v},expression:"sourceDocumentNumber"}}),_c('transition',{attrs:{"name":"slide-fade-down"}},[(errors[0])?_c('b-form-invalid-feedback',{attrs:{"id":"inputLiveFeedback"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)],1)]}}])})],1),_c('div',{staticClass:"col-3"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validated = ref.validated;
return [_c('b-form-group',{attrs:{"label-size":"sm","label":_vm.$t('nir.create.form.lblDataDocument')}},[_c('b-input-group',[_c('b-form-input',{directives:[{name:"facade",rawName:"v-facade",value:('## / ## / ####'),expression:"'## / ## / ####'"}],attrs:{"type":"text","autocomplete":"off","lazy-formatter":"","readonly":!_vm.isDraft,"state":!_vm.isDraft ? null : errors[0] ? false : ((valid && validated) ? true : null),"formatter":_vm.dateFormatterDataDocument},model:{value:(_vm.dataDocumentValue),callback:function ($$v) {_vm.dataDocumentValue=$$v},expression:"dataDocumentValue"}}),(_vm.isDraft)?_c('b-input-group-append',[_c('b-form-datepicker',{attrs:{"button-only":"","button-variant":"outline-secondary","right":"","locale":"ro","aria-controls":"example-input","size":"sm","max":_vm.dataDocumentValueDatepickerMax},on:{"context":_vm.onContextDataDocument},model:{value:(_vm.dataDocumentValueDatepicker),callback:function ($$v) {_vm.dataDocumentValueDatepicker=$$v},expression:"dataDocumentValueDatepicker"}})],1):_vm._e()],1)],1)]}}])})],1),_c('div',{staticClass:"col-3"},[_c('ValidationProvider',{attrs:{"rules":{afterOrEqual: _vm.dataDocumentValue, required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var validated = ref.validated;
return [_c('b-form-group',{attrs:{"label-size":"sm","label":_vm.$t('nir.create.form.lblDataScadenta')}},[_c('b-input-group',[_c('b-form-input',{directives:[{name:"facade",rawName:"v-facade",value:('## / ## / ####'),expression:"'## / ## / ####'"}],attrs:{"type":"text","autocomplete":"off","lazy-formatter":"","readonly":!_vm.isDraft,"state":!_vm.isDraft ? null : errors[0] ? false : ((valid && validated) ? true : null),"formatter":_vm.dateFormatterDataScadenta},model:{value:(_vm.dataScadentaValue),callback:function ($$v) {_vm.dataScadentaValue=$$v},expression:"dataScadentaValue"}}),(_vm.isDraft)?_c('b-input-group-append',[_c('b-form-datepicker',{attrs:{"button-only":"","button-variant":"outline-secondary","right":"","locale":"ro","aria-controls":"example-input","size":"sm","min":_vm.dataScadentaValueDatepickerMin},on:{"context":_vm.onContextDataScadenta},model:{value:(_vm.dataScadentaValueDatepicker),callback:function ($$v) {_vm.dataScadentaValueDatepicker=$$v},expression:"dataScadentaValueDatepicker"}})],1):_vm._e()],1)],1)]}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }